import { render, staticRenderFns } from "./_Layout.vue?vue&type=template&id=1654330a&scoped=true&"
import script from "./_Layout.vue?vue&type=script&lang=js&"
export * from "./_Layout.vue?vue&type=script&lang=js&"
import style0 from "./_Layout.vue?vue&type=style&index=0&id=1654330a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1654330a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VIcon,VMain})
